import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import React, { memo, Suspense, useEffect, useState } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FFFEFA',
      neutralLight3: '#F7F5EF',
      neutralLight2: '#F0EEE6',
      neutralLight1: '#EDE9E2',
      neutralDark4: '#3E424A',
      neutralDark3: '#0E1629',
      neutralDark2: '#060E21',
      neutralDark1: '#000000',
      primaryDark: '#17233E',
      primaryLight: '#9A8E7B',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'utile-display', sans-serif",
    label: "'montserrat', sans-serif",
    paragraph: "'inter-variable', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.heading};
  text-transform: uppercase;
`

const labelStyle = css`
  font-family: ${theme.fontFamily.label};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

export const styles = {
  label: {
    default: css`
      ${labelStyle}
      color: ${theme.colors.variants.primaryLight};
    `,
    dark: css`
      ${labelStyle}
      color: ${theme.colors.variants.neutralDark2};
    `,
    light: css`
      ${labelStyle}
      color: ${theme.colors.variants.neutralLight5};
    `,
    custom: css`
      ${labelStyle}

      font-size: 0.875rem;
      line-height: 1.125rem;
      color: ${theme.colors.variants.neutralLight5};
    `,
  },
  title: {
    xl: css`
      ${titleStyle}
      font-size: 3.25rem;
      line-height: 3.25rem;

      @media (max-width: 1199px) {
        font-size: 3rem;
        line-height: 3rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 2.75rem;
      line-height: 2.75rem;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 2rem;
      line-height: 2rem;

      @media (max-width: 1199px) {
        font-size: 1.875rem;
        line-height: 2rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 1.75rem;
      line-height: 1.75rem;
    `,
    small: css`
      ${titleStyle}
      font-size: 1.5rem;
      line-height: 2rem;
      text-transform: none;
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralDark4};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.625rem;
    letter-spacing: 0;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  fallback?: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  fallback,
}: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  const [isMounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }
  }, [isBrowser])

  return (
    <EmotionThemeProvider theme={theme}>
      <Suspense fallback={fallback}>
        {!isMounted ? (
          fallback
        ) : (
          <>
            <style type="text/css">{mediaStyle}</style>
            <MediaContextProvider>
              {smoothScroll ? (
                <ReactLenis root options={{ duration: 1.4 }}>
                  {children}
                </ReactLenis>
              ) : (
                children
              )}
            </MediaContextProvider>
          </>
        )}
      </Suspense>
    </EmotionThemeProvider>
  )
})
